import "core-js/modules/es.array.push.js";
import Tabbar from "@/components/tabbar";
import { getZhiYaList, guquanSale, postZhiYa } from "@/api";
export default {
  data() {
    return {
      show: false,
      popType: 0,
      // popType === 0 ? '股权出售' : '股权质押分红' 
      password: "",
      num: "",
      list: [],
      gujia: "",
      id: ""
    };
  },
  mounted() {
    getZhiYaList().then(res => {
      this.list = res.data;
      this.gujia = res.gujia;
    });
  },
  methods: {
    closePop() {
      this.show = false;
    },
    openPop(popType, id) {
      // 未登录
      if (!this.userInfo.uid) {
        this.$router.push("/login?redirect=/equity");
        return;
      }
      this.id = id;
      this.popType = popType;
      this.show = true;
    },
    submit(type) {
      if (this.num < 1) {
        this.$toast("请输入数量");
        return;
      }
      if (this.password.length < 3) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      if (type === 0) {
        guquanSale({
          num: this.num,
          pwd: this.password
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.$store.dispatch("getUserInfo");
          this.show = false;
          this.password = "";
          this.num = "";
        });
      } else {
        postZhiYa({
          num: this.num,
          pwd: this.password,
          id: this.id
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.$store.dispatch("getUserInfo");
          this.show = false;
          this.password = "";
          this.num = "";
        });
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  components: {
    Tabbar
  }
};